<template>
  <modal
    name="actions-user-modal"
    @before-open="beforeOpen"
    @closed="clear"
    :adaptive="true"
    height="auto"
    width="100%"
    :maxWidth="973"
    class="modal-table"
  >
    <div class="modal-table-title-container">
      <h3 class="modal-table-title">Actions history</h3>
    </div>
    <table class="actions-table">
      <tbody>
      <tr class="actions-table-row">
        <td>
          <p class="actions-table-date">2020-12-27 17:59:39</p>
        </td>
        <td>Text edit</td>
      </tr>
      </tbody>
    </table>
  </modal>
</template>

<script>
import modal from '@/mixins/modal';
import {mapGetters} from 'vuex';

export default {
  name: "UserActionsModal",
  mixins: [modal],
  computed: {
    ...mapGetters(['selectedUser', 'setTempUser'])
  },
  methods: {
    clear() {
      this.setTempUser(null);
      this.modalActive = false;
    },
    close() {
      this.$modal.hide('actions-user-modal');
    },
  }
}
</script>

<style scoped>

</style>
