<template>
  <div class="users-list block">
    <h2 class="block-wrapper header">Edit users</h2>
    <div class="table-container">
      <Preloader v-if="pending"/>
      <table v-if="list.length > 0" class="users-list-table">
        <thead class="users-list-table-header">
        <tr>
          <td>Login</td>
          <!--        <td>Hide comms</td>-->
          <!--        <td>Status</td>-->
          <td>Group</td>
          <td>Lang</td>
          <!--        <td>Last login</td>-->
          <td>Actions</td>
        </tr>
        </thead>
        <tbody class="users-list-table-body">
        <tr v-for="(u, idx) in users" :key="`user-${idx}`">
          <td>
            <div class="user-list-table-login">
              <p>{{ u.email }}</p>
              <button class="btn orange" @click="changePass(u)">New pass</button>
            </div>
          </td>
          <!--        <td>-->
          <!--          <Checkbox :value="user.comms"/>-->
          <!--        </td>-->
          <!--        <td>-->
          <!--          <Dropdown :value="user.status"/>-->
          <!--        </td>-->
          <td>
            <Dropdown
              :active="getRole(u.role)"
              :disabled="(u.role === 'ADMIN' && user.role !== 'SUPERADMIN') || (u.role === 'SUPERADMIN')"
              :list="roles.filter(r => (r.value !== 'ADMIN' && user.role !== 'SUPERADMIN') || r.value !== 'SUPERADMIN')"
              placeholder="no groups"
              @selected="changeRole($event, u)"
            />
          </td>
          <td>
            <Dropdown
              v-if="u.role === 'USER'"
              :list="listLang"
              :active="getLang(u.language)"
              @selected="setUserLang($event, u)"
            />
            <p v-else>--</p>
          </td>
          <!--        <td>-->
          <!--          <div class="user-list-table-net">-->
          <!--            <p>{{ $moment(user.dateTime).format('YYYY-MM-DD HH:MM:SS') }}</p>-->
          <!--            <p class="net"><strong>IP:</strong>[{{ user.net.country }}]-->
          <!--              <component :is="user.net.country"/>-->
          <!--              {{ user.net.ip }}-->
          <!--            </p>-->
          <!--            <p class="net"><strong>UA:</strong> {{ user.net.system }}</p>-->
          <!--            <p class="net"><strong>Host:</strong> {{ user.net.host }}</p>-->
          <!--          </div>-->
          <!--        </td>-->
          <td>
            <Action :user="u"/>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="table-empty">
        <p v-if="!pending">Empty</p>
      </div>
    </div>
    <APagination ref="pagination" :visible="list.length > 0" :page="page" :count="paginationPages"
                 :callback="setPagination"/>
    <!--    <UserActionsModal/>-->
    <UserLoginHistory/>
  </div>
</template>

<script>
import Ukraine from '@/assets/flags/flag-ua.svg';
import {mapActions, mapGetters} from "vuex";
import {eventBus} from '@/main';
import UserActionsModal from '@/components/modals/UserActionsModal';
import UserLoginHistory from '@/components/modals/UserLoginHistory';
import Preloader from '@/components/common/Preloader';
import languages from '@/utils/languages';

export default {
  name: "UsersList",
  components: {
    Preloader,
    UserLoginHistory,
    APagination: () => import('@/components/common/APagination'),
    // UserActionsModal,
    // Checkbox: () => import('@/components/common/ACheckbox'),
    Dropdown: () => import('@/components/common/ADropdown'),
    Action: () => import('@/components/users/UsersAction'),
    // eslint-disable-next-line vue/no-unused-components
    Ukraine
  },
  data() {
    return {
      list: [],
      page: 1,
      quantity: 30,
      total: 0,
      pending: false,
      listLang: languages
    }
  },
  computed: {
    ...mapGetters(['roles', 'user']),
    users() {
      return this.list.filter(u => u.id !== this.user.id);
    },
    paginationPages() {
      let pageNum = Math.trunc(this.total / this.quantity);
      if ((this.total % this.quantity) > 0) {
        pageNum++;
      }
      return pageNum;
    }
  },
  methods: {
    ...mapActions(['getUsers', 'changeUserRole', 'setTempUser', 'changeUserLang']),
    async setUserLang(lang, user){
      if (lang) {
        await this.changeUserLang({
          language: lang.value,
          id: user.id
        }).then(async () => {
          this.$notify({
            title: 'Change user language',
            text: ' User language changed successfully!',
            type: 'success'
          });
          await this.loadUsers();
        }).catch(error => {
          console.error('Error changing user role:', error);
          this.$notify({
            title: 'Change user lang',
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    getLang(lang) {
      const userLang = this.listLang.find(r => r.value === lang);
      return userLang || null;
    },
    changePass(user) {
      this.setTempUser(user);
      this.$modal.show('change-pass-user-modal')
    },
    getRole(role) {
      const userRole = this.roles.find(r => r.value === role);
      return userRole || null;
    },
    async loadUsers() {
      this.pending = true;
      await this.getUsers({per_page: this.quantity, current_page: this.page}).then(({data, pagination}) => {
        this.pending = false;
        this.list = data;
        this.total = pagination.total;

        if (data.length === 0 && this.page > 1) {
          this.$refs['pagination'].onChangePage(1);
        }
      }).catch(error => {
        this.pending = false;
        console.error('Error get users: ', error);
        this.$notify({
          title: 'Users list',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    async setPagination(num) {
      this.page = num;
      await this.loadUsers();
    },
    async changeRole(role, user) {
      if (role) {
        await this.changeUserRole({
          role: role.value,
          id: user.id
        }).then(async () => {
          this.$notify({
            title: 'Change user role',
            text: 'User role changed successfully!',
            type: 'success'
          });
          await this.loadUsers();
        }).catch(error => {
          console.error('Error changing user role:', error);
          this.$notify({
            title: 'Change user role',
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    }
  },
  async created() {
    eventBus.$on('onLoadUsers', async data => {
      await this.loadUsers();
    });
  }
}
</script>

<style scoped>
.table-container {
  position: relative;
  min-height: 400px;
}
</style>
